@use '../../shared/styles/variables' as vars;
@use '../../shared/styles/mixins' as mixins;

.mainBackground {
  top: 0;
  left: 0;
  width: 100vw;

  background: vars.$landing-main-background url("../../assets/images/main-background.svg") no-repeat center;
  background-size: cover;
}

.main {
  background: url("../../assets/images/job-recruitment.svg") no-repeat center right;
  display: flex;
  background-size: 40% 60%;
  font-family: vars.$font-primary;
}

.logo {
  height: 64px;
}

.textContent {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  text-align: start;
  max-width: 60%;

  box-sizing: border-box;
  height: 100vh;
  padding: 64px 0 64px 64px;
}

.title {
  color: vars.$text-primary;
  font-size: 48px;
  font-style: normal;
  font-weight: 700;
  line-height: 64px;
  margin-bottom: 12px;
}

.secondaryText {
  color: vars.$text-primary;
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: 32px;
}

.buttonDescription {
  color: vars.$gray;
  font-size: 18px;
  font-style: normal;
  font-weight: 700;
  line-height: 28px;
}

.button {
  display: inline-block;
  padding: 10px 20px;
  font-size: 16px;
  cursor: pointer;
  text-align: center;
  text-decoration: none;
  outline: none;
  color: vars.$white;
  background-color: vars.$primary;
  border: 2px solid vars.$primary;
  border-radius: 12px;
  transition: all 0.3s;
}

.button:hover {
  background-color: vars.$white;
  color: vars.$primary;
}

@media (min-width: mixins.$large-laptop) {
  .textContent {
    min-width: 55%;
  }
}

@media (max-width: mixins.$medium-desktop) {
  .main {
    background: none;
  }

  .textContent {
    align-items: center;
    text-align: center;
    padding: 32px;
    max-width: 100%;
  }

  .logo {
    margin-right: 10px;
    height: 58px;
  }
}

@media (max-width: mixins.$mobile-portrait) {


  .title {
    font-size: 32px;
    font-style: normal;
    font-weight: 700;
    line-height: 48px
  }

  .secondaryText {
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px;
  }

  .logo {
    margin-right: 10px;
    height: 52px;
  }
}

@media (max-width: mixins.$mobile-large) {
  .textContent .buttonContainer {
    display: flex;
    flex-direction: column;
    align-items: stretch;
  }

  .buttonContainer .button {
    width: 100%;
    margin-bottom: 10px;
  }

  .buttonContainer .button:last-child {
    margin-bottom: 0;
  }

  .logo {
    margin-right: 10px;
    height: 44px;
  }
}

@import '../../shared/styles/variables';

.footer {
  font-family: $font-primary;
  background: #151515;

  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  height: 100%;
  padding: 32px;
}

.logoContainer {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-bottom: 8px;
}

@import '../../shared/styles/variables';

.wrapper {
  width: 100%;
  margin-left: auto;
  margin-right: auto;
  overflow-x: hidden;
}

.container {
  padding: 64px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  font-family: $font-primary;
}

.title {
  text-align: center;
  color: $text-primary;
  font-size: 30px;
  font-weight: 700;
  line-height: 42px;
  margin-bottom: 32px;
}

.scrollingWrapper {
  display: flex;
  flex-wrap: nowrap;
  overflow-x: hidden;
  width: calc(116px * 16 * 2);
  animation: scrollLogo 80s linear infinite;
}

.logo {
  width: 116px;
  height: 35px;
  margin-right: 32px;
}


@keyframes scrollLogo {
  0% {
    transform: translateX(0);
  }
  100% {
    transform: translateX(-50%);
  }
}


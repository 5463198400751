@import '../../shared/styles/variables';

.features {
  background: $landing-fade-background;
}

.wrapper {
  max-width: 1200px;
  margin: 0 auto;
  padding: 64px;
  height: 100%;
}

.container {
  display: flex;
  width: 100%;
  height: 512px;
  overflow: auto;
  flex-direction: row;
  font-family: $font-primary;
  align-items: flex-end;
}

.textContainer {
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  gap: 10px;
  flex: 1;
}

.title, .description {
  color: $text-primary;
  font-style: normal;
}

.title {
  font-size: 30px;
  font-weight: 700;
  line-height: 42px;
}

.description {
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  margin-bottom: 32px;
}

.image {
  flex: 1;
  max-width: 50%;
  height: 100%;
  margin-left: 64px;
}

.btnContainer {
  display: flex;
  gap: 16px;
}

.btnArrow {
  display: inline-block;
  height: 44px;
  width: 44px;
  cursor: pointer;
  background-color: $white;
  border: 1px solid $border;
  border-radius: 12px;
  transition: transform 0.3s;
  box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
}

.btnArrow:hover {
  transform: scale(1.06);
}

@media (max-width: 880px) {
  .wrapper {
    padding: 32px;
  }

  .container {
    height: 100%;
    flex-direction: column-reverse;
    align-items: center;
  }

  .textContainer, .image {
    width: 100%;
  }

  .image {
    flex: none;
    max-width: 100%;
    max-height: 350px;
    margin: 32px 0 0;
  }

  .description {
    margin-bottom: 16px;
  }
}

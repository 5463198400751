@import '../../shared/styles/variables';

.background {
  width: 100%;
  position: relative;
}

.background:before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 50%;
  background: $landing-fade-background;
  z-index: -1;
}

.background:after {
  content: '';
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 50%;
  background: $white;
  z-index: -1;
}

.background .clip {
  background: $primary;
  clip-path: polygon(0 10%, 100% 0%, 100% 90%, 0 100%);
  padding: 64px 0;
  position: relative;
}

.wrapper {
  max-width: 1200px;
  margin-left: auto;
  margin-right: auto;
  padding: 64px;

  font-family: $font-primary;
}

.container {
  column-count: 3;
  column-gap: 0;
}

.title {
  text-align: center;
  color: $white;
  font-size: 30px;
  font-style: normal;
  font-weight: 700;
  line-height: 42px;
  margin-bottom: 64px;
}

.cardContainer {
  break-inside: avoid;
  padding: 8px;
}

.card {
  display: flex;
  flex-direction: column;
  justify-content: center;
  border-radius: 12px;
  padding: 24px 16px;

  border: 1px solid #E1E1E2;
  background: rgba(252, 252, 252, 0.80);

  backdrop-filter: blur(16px);
}

.name {
  color: $text-primary;
  text-align: center;
  font-size: 18px;
  font-style: normal;
  font-weight: 600;
  line-height: 28px;
  margin-bottom: 10px;
}

.text {
  color: $text-primary;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
}

.icon {
  height: 33px;
  margin-bottom: 16px;
}

@media (max-width: 880px) {
  .wrapper {
    padding: 32px;
  }

  .container {
    column-count: 2;
  }
}

@media (max-width: 600px) {
  .background .clip {
    clip-path: polygon(0 5%, 100% 0%, 100% 95%, 0 100%);
  }

  .container {
    column-count: 1;
  }
}
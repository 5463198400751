@import '../../shared/styles/variables';

.showcase {
  background: $landing-fade-background;
}

.wrapper {
  max-width: 1200px;
  margin-left: auto;
  margin-right: auto;
  padding: 64px;
}

.container {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-auto-rows: auto;
  gap: 16px;
  font-family: $font-primary;
}

.spanTwoRows {
  grid-row: span 2;
}

.card {
  position: relative;
  padding: 16px;
  min-height: 280px;
  display: flex;
  flex-direction: column;
  justify-content: end;
  border-radius: 12px;
  transition: transform 0.3s ease, background-size 0.3s ease;
  background-size: cover;
  background-position: center;
  overflow: hidden;

  &::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba(0, 0, 0, 0.4);
    z-index: 1;
    transition: background-color 0.3s ease;
  }

  &:hover {
    transform: scale(1.015);
  }
}

.title {
  font-size: 30px;
  font-style: normal;
  font-weight: 700;
  line-height: 42px;
  margin-bottom: 16px;
}

.description {
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: 28px;
  margin-bottom: 16px;
}

.title, .description {
  z-index: 2;
  color: $white;
}

@media screen and (max-width: 880px) {
  .wrapper {
    padding: 32px;
  }

  .container {
    display: flex;
    flex-direction: column;
  }

  .spanTwoRows {
    grid-row: auto;
  }
}
@import '../../shared/styles/variables';

.wrapper {
  max-width: 1200px;
  margin-left: auto;
  margin-right: auto;
  padding: 64px;

  font-family: $font-primary;
}

.container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
}

.title {
  font-size: 30px;
  font-style: normal;
  font-weight: 700;
  line-height: 42px;
  color: $text-primary;
  margin-bottom: 64px;
}

.card {
  padding: 16px;
  border: 1px solid $border;
  border-radius: 12px;
}

.clientInfoContainer {
  display: flex;
  align-items: center;
  margin-bottom: 12px;
}

.avatar {
  margin-right: 12px;
  width: 32px;
  height: 32px;
  border-radius: 16px;
}

.name,
.characteristic,
.text {
  color: $text-primary;
  font-size: 18px;
  font-style: normal;
  font-weight: 500;
  line-height: 28px;
}

.characteristic {
  color: $text-primary-fade;
  font-size: 16px;
}

.text {
  font-size: 20px;
  font-weight: 400;
  line-height: 32px;
}

@media (max-width: 880px) {
  .wrapper {
    padding: 32px;
  }
}

@import '../../shared/styles/variables';

.background {
  width: 100%;
  position: relative;
}

.background:before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 50%;
  background: $white;
  z-index: -1;
}

.background .clip {
  background: $landing-black-background;
  clip-path: polygon(0 10%, 100% 0%, 100% 100%, 0 100%);
  padding: 64px 0;
  position: relative;
}

.wrapper {
  max-width: 1200px;
  margin-left: auto;
  margin-right: auto;

  padding: 64px;

  @media (max-width: 880px) {
    padding: 32px;
  }
}

.container {
  display: flex;
  width: 100%;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-family: $font-primary;


  .content {
    display: flex;
    width: 100%;
    flex-direction: column;
    align-items: center;
  }

  .title {
    font-size: 30px;
    font-style: normal;
    font-weight: 700;
    line-height: 42px;
    margin-bottom: 12px;
    color: $white;
  }

  .description {
    font-size: 18px;
    font-style: normal;
    font-weight: 400;
    line-height: 28px;
    color: $white;
  }

  .form {
    display: flex;
    flex-direction: column;
    width: 100%;
    max-width: 880px;
    margin: 0 auto;

    .block {
      display: flex;
      flex-wrap: wrap;
      align-items: flex-start;
      gap: 0 32px;

      > div {
        display: flex;
        width: calc(50% - 16px);;
        flex-direction: column;
      }

      @media (max-width: 880px) {
        gap: 16px;
        flex-direction: column;

        > div {
          width: 100%;
          flex-basis: auto;
        }
      }
    }

    .nameInput {
      color: $text-white-fade;
      font-size: 18px;
      font-style: normal;
      font-weight: 500;
      line-height: 28px;
      margin-bottom: 12px;
    }

    .input {
      font-size: 18px;
      font-style: normal;
      font-weight: 500;
      line-height: 28px;

      padding: 10px;
      height: 32px;
      color: $white;
      border: 1px solid $base-border;
      background: $primary-black;
      border-radius: 12px;
    }

    .textarea {
      display: flex;
      font-family: Inter, sans-serif;
      font-size: 20px;
      font-style: normal;
      font-weight: 400;
      line-height: 32px;
      padding: 16px;

      color: $white;
      border: 1px solid $base-border;
      background: $primary-black;
      border-radius: 12px;
      min-height: 182px;

      resize: vertical;
    }

    .errorText {
      color: #e01717;
      font-size: 12px;
      font-weight: 400;
      margin-top: 12px;
    }

    .button {
      width: 100%;
      font-size: 20px;
      font-style: normal;
      font-weight: 600;
      line-height: 32px;
      color: $text-primary-black;

      padding: 10px 20px;

      cursor: pointer;
      height: 58px;
      background-color: $primary;
      border: none;
      border-radius: 12px;
      transition: all 0.3s;
    }

    .button:hover {
      background-color: lighten($primary, 5%);
      color: $text-primary-black;
      transform: scale(1.01);
    }
  }
}

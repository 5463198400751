@import '../../shared/styles/variables';

.background {
  background: $landing-black-background;
}
.wrapper {
  max-width: 880px;
  margin-left: auto;
  margin-right: auto;
}

.container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-family: $font-primary;
  width: 100%;
  padding-bottom: 64px;
}

.block {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  font-family: $font-primary;
}

.element {
  width: 100%;
  flex-grow: 1;
  padding: 24px;
}

.title {
  color: $white;
  font-size: 30px;
  font-style: normal;
  font-weight: 700;
  line-height: 42px
}

.officeTitle {
  text-align: center;
  color: $white;
  font-size: 18px;
  font-style: normal;
  font-weight: 600;
  line-height: 28px;
}

.officeAddress {
  text-align: center;
  color: $white;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
}

.callUs {
  color: $white;
  font-size: 24px;
  font-style: normal;
  font-weight: 700;
  line-height: 42px
}

.number {
  text-align: center;
  color: $white;
  font-size: 18px;
  font-style: normal;
  font-weight: 500;
  line-height: 24px;
}

@media (max-width: 720px) {
  .block {
    flex-direction: column;
    margin: 32px;
  }

  .element {
    flex-grow: 0;
    width: 100%;
  }

  .officeAddress {
    text-align: center;
  }
}
$primary: #FB5E3C;
$primary-black: #141414;
$text-primary: #1A1A1A;
$text-primary-fade: #1A1A1A5C;
$text-primary-black: #141414;
$text-white-fade: #FFFFFFB2;
$text-red: #DC2828;
$secondary: #D2E3FC;
$focus: #FF6D7E;
$focus-hover: #ff4f66;
$gray: #A1A1AA;
$fade-gray: #A1A1AA;
$white: #FFFFFF;
$border: #E1E1E2;
$base-border: #3D3D3D;
$landing-primary-background: #FB5E3C;
$landing-fade-background: #F9F9F9;
$landing-black-background: #232323;

$landing-main-background: #ffffff;

@font-face {
  font-family: 'Source Code Pro';
  src: url('https://fonts.googleapis.com/css2?family=Source+Code+Pro:wght@400;600&display=swap') format('woff2'),
  url('https://fonts.googleapis.com/css2?family=Source+Code+Pro:wght@400;600&display=swap') format('woff');
  font-weight: 400;
  font-style: normal;
}

$font-primary: 'Inter', sans-serif;
